<template>
  <div>
    <v-row class="d-flex justify-space-between align-center mx-2">
      <page-header :title="title" :menu="menu" />
      <internal-menu :menuItems="menuItems" />
    </v-row>

    <v-dialog v-model="deleteDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline"> Excluir Registro </v-card-title>
        <v-card-text
          >Confirma a Exclusão Permanentemente do Registro? Após Excluir, NÃO
          Será Possível REVERTER</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="deleteDialog = false">
            CANCELAR
          </v-btn>
          <v-btn color="red" text @click="deleteItem"> EXCLUIR </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.opened"
      :bottom="snackbar.y === 'bottom'"
      :color="snackbar.color"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.opened = false"
          >Ok</v-btn
        >
      </template>
    </v-snackbar>
    <v-container fluid>
      <v-card>
        <v-toolbar elevation="0" height="80">
          <v-row class="d-flex justify-space-around align-center">
            <v-col cols="8" md="4">
              <v-text-field
                filled
                outlined
                rounded
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-spacer />
            <v-col cols="4">
              <v-btn
                width="80%"
                color="#2ca01c"
                to="/base/product/create"
                class="hidden-sm-and-down"
                dark
              >
                <v-icon>mdi-plus-thick</v-icon>
                Novo Produto</v-btn
              >
              <v-btn
                dark
                color="#2ca01c"
                to="/base/product/create"
                class="hidden-md-and-up"
              >
                <v-icon>mdi-plus-thick</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="products"
          :search="search"
        >
          <template v-slot:[`item.price`]="{ item }">
            <span>R$ {{ formatPrice(item.price) }}</span>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn text small :to="`/base/product/${item.id}/edit`">
              <v-icon small class="mr-2">mdi-pencil</v-icon></v-btn
            >
            <v-icon small class="mr-2" @click="deleteOpen(item.id)"
              >mdi-eraser</v-icon
            >
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import http from "@/services/http";
import { Money } from "v-money";
import PageHeader from "@/components/core/PageHeader.vue";
import InternalMenu from "@/components/core/InternalMenu.vue";
export default Vue.extend({
  name: "ProductsList",
  components: { Money, PageHeader, InternalMenu },
  data: () => ({
    valid: true,
    title: "Produtos",
    menuItems: [
      {
        icon: "mdi-storefront",
        title: "Acesse",
        permission: "all",
        subs: [
          {
            icon: "mdi-storeftont",
            title: "Categorias de Produtos",
            to: "/base/categories",
            permission: "digitech_plus.users.get_many",
          },
          {
            icon: "mdi-storeftont",
            title: "Clientes",
            to: "/base/customers",
            permission: "immobile_tech.immobile.customers.get_many",
          },
          {
            icon: "mdi-storeftont",
            title: "Colaboradores",
            to: "/immobile/employees",
            permission: "immobile_tech.immobile.employees.get_many",
          },
        ],
      },
    ],
    menu: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Produtos",
        disabled: true,
        href: "/base/products",
      },
    ],
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      precision: 2,
      masked: false,
    },

    deleteDialog: false,
    registerDialog: false,
    fullScreen: false,
    products: [],
    categories: [],
    productId: null,
    file: "",
    fileName: "",
    urlPreview:
      "https://pedbem.blob.core.windows.net/pedbem/photo-99135_640.png",
    product: {
      name: "",
      description: "",
      stock: 2,
      categoryId: "",
      price: 0.0,
      imageUrl: "",
      productType: "PRODUTO",
      promotionalPrice: 0.0,
      costPrice: 0.0,
      ncm: "",
      brand: "",
      bardCode: "",
      manufacturer: "",
      model: "",
      unity: "UN",
      stockControl: true,
    },
    search: "",
    loading: false,
    productId: "",
    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 6000,
      x: null,
      y: "top",
    },
    tributeId: "",

    headers: [
      {
        text: "Nome",
        align: "start",
        value: "name",
        filtering: true,
      },
      {
        text: "Estoque",
        value: "stock",
        filtering: true,
      },
      {
        text: "Preço Inicial R$",
        value: "price",
        filtering: true,
      },

      { text: "Ações", value: "actions", filtering: false, sortable: false },
    ],
  }),
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) this.registerOrUpdate();
    },
    setIconScreen() {
      if (this.fullScreen) return "mdi-fullscreen-exit";
      else return "mdi-fullscreen";
    },
    handleFileUpload() {
      this.file = this.$refs.fileRef.files[0];
      this.fileName = this.$refs.fileRef.files[0].name;
      this.urlPreview = URL.createObjectURL(this.file);
    },
    openFileDialog() {
      document.getElementById("file-upload").click();
    },
    async editItem(item) {
      await http.get(`products/${item.id}`).then(async (res) => {
        const { data } = await res;
        this.product = data;
        this.productId = item.id;
        this.urlPreview = data.imageUrl;
        this.registerDialog = true;
      });
    },
    deleteOpen(itemId) {
      this.productId = itemId;
      this.deleteDialog = true;
    },
    getCategories() {
      http.get("categories").then((res) => {
        this.categories = res.data;
      });
    },

    async registerOrUpdate() {
      this.loading = true;
      if (this.file) {
        const formData = new FormData();
        formData.append("file", this.file);
        formData.append("systemClientId", "pedbem");
        formData.append("companyId", "pedbem");
        formData.append("name", new Date().toString());
        formData.append("path", "erp/prodcuts");
        formData.append("type", "image-product");
        formData.append("public", 1);
        await http.post("base/files", formData).then(
          (d) => {
            this.product.imageUrl = d.data.imageUrl;
          },
          () => {
            this.snackbar.text =
              "Houve um erro ao fazer upload da IMAGEM, tente novamente";
            this.snackbar.opened = true;
            this.snackbar.color = "red";
          }
        );
      }
      this.product.promotionalPrice = this.product.price;
      if (this.productId) {
        await http.put(`products/${this.productId}`, this.product).then(
          () => {
            this.snackbar.text = "Produto atualizado com sucesso";
            this.snackbar.color = "success";
            this.snackbar.opened = true;
            this.clearItem();
          },
          (err) => {
            const { response } = err;
            const message =
              typeof response?.data === "string"
                ? response?.data
                : response?.data?.title;
            this.snackbar.text = "Erro ao atualizar o produto: " + message;
            this.snackbar.color = "red";
            this.snackbar.opened = true;
          }
        );
      } else {
        await http.post("products", this.product).then(
          (res) => {
            this.snackbar.text = "Produto cadastrado com sucesso";
            this.snackbar.color = "success";
            this.snackbar.opened = true;
            this.clearItem();
          },
          (err) => {
            const { response } = err;
            const message =
              typeof response?.data === "string"
                ? response?.data
                : response?.data?.title;
            this.snackbar.text = "Erro ao cadastrar o produto: " + message;
            this.snackbar.color = "red";
            this.snackbar.opened = true;
          }
        );
      }
      this.loading = false;
    },
    getItems() {
      this.loading = true;
      http.get("products").then(
        (data) => {
          this.products = data.data;
          this.loading = false;
        },
        (err) => {
          this.snackbar.color = "red";
          this.snackbar.text =
            "Hove um erro ao buscar os registros. Atualize a página por favor";
          this.snackbar.opened = true;
        }
      );
    },
    deleteItem() {
      http.delete(`products/${this.productId}`).then(
        () => {
          this.snackbar.color = "green";
          this.snackbar.text = "Registro Excluido com Sucesso!";
          this.snackbar.opened = true;
          this.deleteDialog = false;
          this.getItems();
        },
        () => {
          this.snackbar.color = "red";
          this.snackbar.text = "Erro ao Excluir Registro. Tente Novamente!";
          this.snackbar.opened = true;
        }
      );
    },
  },
  mounted() {
    this.getItems();
    this.getCategories();
  },
});
</script>
<style>
.money {
  background-color: rgb(0, 126, 0);
  height: 55px;
  padding: 15px;
  font-weight: bolder;
  font-size: 16px;
  width: 100%;
  color: aliceblue;
  border-radius: 5px;
}
.money:focus {
  outline: none !important;
  background-color: rgb(7, 85, 7);
}
</style>
